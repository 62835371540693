// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout';
import users from '@src/views/apps/user/store/reducer';
import invoice from '@src/views/apps/invoice/store/reducer';
import licitacoes from '@src/views/apps/licitacoes/store/reducer';

const rootReducer = combineReducers({
  auth,
  users,
  navbar,
  layout,
  invoice,
  licitacoes,
});

export default rootReducer
